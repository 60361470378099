<template>
	<div>
		<b-form-select
			v-model="selected"
			:options="options"
			:class="validateError ? 'box-border-color' : ''"
			@input="handleInput"
		/>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null,
			},
			validateError: {
				default: null,
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue;
			}
		},
		data() {
			return {
				selected: null,
				options: [
					{value: null, text: this.$t("select")},
					{value: 1, text: this.$t('fall')},
					{value: 2, text: this.$t('spring')},
					{value: 3, text: this.$t('summer')},
				],
			};
		},
		created() {
			this.selected = this.value
		},
		methods: {
			handleInput(event) {
				this.$emit("input", event);
			}
		}
	}
</script>
